import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { Link } from 'gatsby'
import FormatDate  from './formatDate'

const Flex = styled(Link)`
    
    max-width:1000px;
    display: flex;
    align-items: flex-start;
    flex-direction:row;
    margin: 7rem auto;
    gap:5.5rem;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction:column;
        gap:2rem;
        } 
`

const Illustration = styled.div`   
    width:320px;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
    width:100%;
} 
`

const NewsResumeBox = ({title, sousTitre, date, coverImage, slug, teaser}) => {

    return (
        <Flex to={`/news/${slug}`} title={title} className="news-link">
            <div className="link_background"></div>
            <Illustration>
                <GatsbyImage alt={title} image={coverImage.gatsbyImageData} />
            </Illustration>
            <div className="news-resume-box">   
                <span className="date-small"><FormatDate date={date}/></span>
                <h3 className='h3'>{title}</h3>
                <h4 className='h4'>{sousTitre}</h4>
                <p>{teaser}</p>
            </div>
        </Flex>
     )
}
export default NewsResumeBox