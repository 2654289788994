import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import { StaticImage } from 'gatsby-plugin-image'
import NewsResumeBox  from '../components/newsResumeBox'
import Button from "../components/button"
import { Fade } from 'react-reveal'

const FlexHomeNews = styled.div`
    margin:0 auto;
    max-width:1150px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction:row;
    padding-bottom: 10rem;
    gap:8rem;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction:column;
        gap:2rem;
        } 
`

const IllustrationHomeNews = styled.div`   
  
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      transform:rotate(-3.93deg);
    }
    box-shadow: 5px 6px 4px rgb(0 0 0 / 25%);
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
     width:100%;
    } 
`


const Home = ({data}) => {
  return (
  <Layout>
    <Seo meta={data.home.seoMetaTags} />
    <section className="bg-homepage">
      <div className="container">
        { data.lastnews.edges.map(( item,i) => ( 
          <FlexHomeNews key={i} > 
             <Fade left duration={700} distance="50px">
                <IllustrationHomeNews>
                  <GatsbyImage alt={item.node.title} image={item.node.coverImage.gatsbyImageData} />
                </IllustrationHomeNews>
              </Fade>
            <div className="news-resume-box">
              <Fade down duration={700} distance="70px">
                {/*<span className="date-small gray"><FormatDate date={item.node.date}/></span>*/}
                <h1>{item.node.title}</h1>
                <h2>{item.node.sousTitre}</h2>
                <p>{item.node.teaser}</p>
                <Button to={`news/${item.node.slug}`}>Découvrir</Button>
              </Fade>
            </div>  
          </FlexHomeNews>
        ))
        }
      </div>
    </section>  
    <section className="full-green">
      <div className="container">
        { data.allnews.edges.map(( itemNews,i) => ( 
          <NewsResumeBox key={i} {...itemNews.node} />
        ))
        }
      </div>
    </section>  
  </Layout>
  )
}

export default Home


export const newsQuery = graphql`
   query news {
    home:datoCmsHomepage {
      seoMetaTags  {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    lastnews:allDatoCmsNews(limit: 1, sort: {order: DESC, fields: date}) {
      edges {
        node {
          id
          title
          sousTitre
          teaser
          date
          slug
          coverImage {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
        }
      }
    }

    allnews:allDatoCmsNews(limit: 2, skip:1, sort: {order: DESC, fields: date}) {
      edges {
        node {
          id
          title
          sousTitre
          teaser
          date
          slug
          coverImage {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
        }
      }
    }
  }
`;